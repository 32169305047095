import { Component } from 'react';

class EmailPreview extends Component {
    render() {
        let { name, title, email } = this.props.data;
        // console.log(this.props.data)
        return (
            <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: "middle", fontSize: "medium", fontFamily: "Arial" }}>
                <tbody>
                    <tr>
                        <td>
                            <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: "middle", fontSize: "medium", fontFamily: "Arial" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: "top" }}>
                                            <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: "middle", fontSize: "medium", fontFamily: "Arial" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ textAlign: "center", width: "80px", maxWidth: "80px", display: "block" }}><img src="https://www.kozmo.world/assets/imgs/kozmo-logo.png" alt="KOZMO Logo" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ width: "40px" }}>
                                            <div></div>
                                        </td>
                                        <td style={{ padding: "0px", verticalAlign: "middle" }}>
                                            <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: "middle", fontSize: "medium", fontFamily: "Arial", width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <h4 style={{ margin: "0px", fontSize: "16px", color: "rgb(0, 0, 0)" }}>{name}</h4>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p style={{ margin: "0px", color: "rgb(0, 0, 0)", fontSize: "14px", lineHeight: "22px" }}>{title}</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: "middle", fontSize: "medium", fontFamily: "Arial" }}>
                                                <tbody>
                                                    <tr style={{ verticalAlign: "middle" }}>
                                                        <td style={{ padding: "0px" }}> <a href={`mailto:${email}`} style={{ textDecoration: "none", color: "rgb(0, 0, 0)", fontSize: "12px" }}><span>{email}</span></a> | <a href="https://www.kozmo.world" style={{ textDecoration: "none", color: "rgb(0, 0, 0)", fontSize: "12px" }}><span>www.kozmo.world</span></a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default EmailPreview;