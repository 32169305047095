import React from 'react';
import ticker from '../assets/ticker-group.svg';

const Ticker = ({ position }) => {
    const tickerArr = [ticker, ticker, ticker, ticker];

    return (
        <div className={`ticker ${position}`}>
            <span>
                {
                    tickerArr.map((asset, index) => {
                        return <img key={index} src={asset} alt="" />
                    })
                }
            </span>
        </div >
    );
}

export default Ticker;