import React, { useEffect, useState } from 'react';
import '../../styles/styles.scss'
import Nav from '../Nav';
import Ticker from '../Ticker';
import Loader from '../Loader';
import MagnetCursor from '../Cursor';
import About from '../../pages/About';
import { Outlet } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';

const Layout = ({ children }) => {
    const [isInfoActive, setInfoActive] = useState(true);
    const showInfo = () => {
        setInfoActive(prevInfoActive => !prevInfoActive);
    }

    useEffect(() => {
        const cursor = new MagnetCursor();
    }, []);

    return (
        <>
            {/* <Loader /> */}
            <Ticker position="top" />
            <main className='content'>
                <div className="circle-cursor circle-cursor--inner"></div>
                <div className="circle-cursor circle-cursor--outer"></div>
                {/* <Nav showInfo={showInfo} isInfoActive={isInfoActive} /> */}
                <About isInfoActive={isInfoActive} />
                <Outlet />
            </main>
            <Ticker position="bottom" />
        </>
    )
}

export default Layout;