import React from 'react';
import Stars from '../assets/Kozmo-Back.png'
const Plain = () => {
    return (
        <>
            <img
                src={Stars}
                alt='Kozmo stars logo'
                style={{ width: '100%', maxWidth: '1000px' }}
            />
        </>
    )
}

export default Plain;