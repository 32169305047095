import React, { useState, useEffect } from 'react';
import ShirtFrontStatic from '../assets/F_static.png'
import ShirtBackStatic from '../assets/B_static.png'
import { Columns, Button, Form } from 'react-bulma-components';
import shopData from '../products.json'
import Product from '../components/Product'

let sizes = shopData[0]['custom1-options']
// console.log(shopData)

const Shop = () => {
    const [sizeValue, setSizeValue] = useState(true);
    // const [isOver, setOver] = useState(false);

    useEffect(() => {
        //Add Snipcart
        const script = document.createElement('script');
        const div = document.createElement('div');

        script.src = "https://cdn.snipcart.com/themes/v3.3.3/default/snipcart.js"
        script.async = true

        div.id = "snipcart";
        div.dataset.configModalStyle = "side";
        // div.dataset.apiKey = "MTJkYjdhYjgtODE2ZS00NzYwLTk2M2YtODc0NjRiOTRiMDJmNjM3ODM3NDU1NDE3NjkxMjk0";
        div.dataset.apiKey = "M2ZhNmFhYzctZjY3NS00ZjM5LTkwNmYtZDYwZGZhYjg4MWZhNjM3ODM3NDU1NDE3NjkxMjk0";
        div.hidden = true;
        let isLoaded = document.body.contains(document.querySelector('#snipcart'));

        if (!isLoaded) {
            document.body.appendChild(script)
            document.body.appendChild(div)
        }
    }, [])

    const selectSize = (e) => {
        e.target.className = 'selected';
        return setSizeValue(e.target.value);
    };

    return (
        <div className='shop m-0 p-0'>
            <Columns centered>
                <Columns.Column>
                    <Product
                        data={["100% Cotton", "Max Heavyweight", "Slightly Oversized", "3.5% Lycra Mock Neck Ribbing"]}
                        alignment='left'
                        image={ShirtFrontStatic}
                        alt="Front of Kozmo T-Shirt"
                    />
                </Columns.Column>
                <Columns.Column>
                    <Product
                        data={["KOZMO BLACK", "DESIGN TEAM TEE", "$35.00"]}
                        alignment='right'
                        image={ShirtBackStatic}
                        alt="Back of Kozmo T-Shirt"
                    />
                </Columns.Column>
            </Columns >

            <div className="button-group">
                <div className="sizes">
                    <Form.Control>
                        {sizes.map(size => {
                            return (
                                <Form.Radio
                                    key={size}
                                    value={size}
                                    name={size}
                                    checked={sizeValue === size}
                                    onChange={selectSize}
                                    className={sizeValue === size ? 'selected' : ''}
                                >{size}</Form.Radio>
                            )
                        })}
                    </Form.Control>
                </div>
                {
                    sizeValue &&
                    <Button
                        className='pill is-rounded snipcart-add-item'
                        data-item-id={shopData[0].id}
                        // data-item-image={ShirtFront}
                        data-item-name={shopData[0].name}
                        data-item-price={shopData[0].price.toFixed(2)}
                        data-item-custom1-name={shopData[0]['custom1-name']}
                        data-item-custom1-options={shopData[0]['custom1-options'].join('|')}
                        data-item-custom1-value={sizeValue ? sizeValue : null}
                        data-item-url="products.json"
                    >
                        Add to Cart
                    </Button>
                }
            </div>
        </ div>
    );
}

export default Shop;