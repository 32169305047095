import React from 'react';
import { Link } from "react-router-dom";
// import { Button } from 'react-bulma-components';
import { Helmet } from 'react-helmet-async';
const NoPage = () => {
    return (
        <>
            <Helmet>
                <title>KOZMO | 404 </title>
            </Helmet>
            <h1 style={{ fontSize: '20vw', fontFamily: 'KOZMOUltra' }}>404</h1>
            <Link to="/" >Return to Shop</Link>
        </>
    )
}

export default NoPage;