import EmailPreview from '../components/EmailPreview';
import { Component } from 'react';
import Clipboard from 'clipboard';
import { Button, Container } from 'react-bulma-components'

class EmailSig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'Your Name',
            title: 'Author, Writer, Exotic Olive Oil Taster',
            email: 'your.email@kozmo.world',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleCopy() {
        console.log('Copied!')
        new Clipboard('#clipBtn', {
            text: function () {
                return document.querySelector('#signature').innerHTML;
            }
        });
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <Container className="email-signature">
                <div className="sig-form">
                    <form id="form-fields">
                        <label htmlFor="name">Name:</label>
                        <input type="text" name="name" placeholder="Your Name" onChange={this.handleChange} />

                        <label htmlFor="title">Title:</label>
                        <input type="text" name="title" placeholder="Optional, e.g. CTO, Designer" onChange={this.handleChange} />

                        <label htmlFor="email">Email:</label>
                        <input type="email" name="email" placeholder="yourname@kozmo.world" onChange={this.handleChange} />
                    </form>
                </div>
                <div className="sig-result">
                    <div id="preview">
                        <h3>Signature Preview</h3>
                        <div id="signature">
                            <EmailPreview data={this.state} />
                        </div>
                    </div>
                    <Button id="clipBtn" className="pill is-rounded" onClick={this.handleCopy}>Copy To Clipboard</Button>
                </div>
            </Container>
        );
    }
}

export default EmailSig;
