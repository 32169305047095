import React from 'react';
import arrow from '../assets/scroll-arrow.svg'
// import { Button } from 'react-bulma-components';

const About = ({ isInfoActive }) => {
    return (
        <div className={`about${!isInfoActive ? '' : ' active'}`}>
            {isInfoActive && <img src={arrow} alt="" className="scrollArrow" />}
            <div className="info">
                <h2>Studio</h2>
                <p>WORKING IN BOTH PHYSICAL AND DIGITAL SPACES, WE OFFER CREATIVE, PRODUCTION, AND STRATEGY SERVICES TO A GLOBAL FAMILY OF THOUGHT LEADERS AND EXPERTS.</p>
                <p>COLLABORATION IS AT THE CORE OF EACH PROJECT AS WE CONTINUE TO BUILD THINGS WITH A UNIQUE APPROACH.</p>
                <h2>Services</h2>
                <ul>
                    <li>NAMING & BRANDING</li>
                    <li>DESIGN</li>
                    <li>Creative STRATEGY</li>
                    <li>CREATIVE DIRECTION</li>
                    <li>WEB DESIGN/DEVELOPMENT</li>
                </ul>
                <h2>Team</h2>
                <ul>
                    <li>Mose Jobe</li>
                    <li>Kazadi</li>
                    <li>Jordan Hubbard</li>
                </ul>
                <h2>contact</h2>
                <a href="mailto:info@kozmo.world">info@kozmo.world</a>
                <a href="https://www.instagram.com/kozmo.studio" className="instagram">Instagram</a>
            </div>
        </div>
    );
}

export default About;