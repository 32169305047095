import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/Layout/Layout';
import Shop from './pages/Shop';
import NoPage from './pages/NoPage';
import Plain from './pages/Plain';
import EmailSig from './pages/EmailSig';
import Survey from './components/Survey';
import ogimg from './assets/ogimage.png'
const helmetContext = {}
const { helmet } = helmetContext;

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <title>KOZMO</title>
        <link rel="preconnect" href="https://app.snipcart.com" />
        <link rel="preconnect" href="https://cdn.snipcart.com" />
        <link rel="stylesheet" href="https://cdn.snipcart.com/themes/v3.3.3/default/snipcart.css" />
        <meta property="og:title" content="🌍 KOZMO 🌐" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogimg} />
        <meta property="og:url" content="https://www.kozmo.world" />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Plain />} />
            <Route path="*" element={<NoPage />} />
            <Route path="/email-gen" element={<EmailSig />} />
            <Route path="/survey" element={<Survey />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
