import React from 'react';

function Survey() {
    return (
        <>
            <iframe title="Client Survey" src="https://docs.google.com/forms/d/e/1FAIpQLSfvygd5CdZ3FoVUL9yNwIYuHW1o_QiRRtHwwDBD2xQHbAzlOw/viewform?embedded=true" width="640" height="5143" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
        </>
    );
}

export default Survey;